/**
 * @fileoverview HOC wrapper for security context
 *
 * usage:
 * <code>
 *     class MyComponent extends Component {
 *         const check = () => {
 *             const {isGranted} = this.props;
 *
 *             if (isGranted(PERMISSION.MODIFY_USER)) {
 *               ...
 *             }
 *         };
 *         ...
 *     }
 *
 *     export default withSecurity(MyComponent);
 * </code>
 */
import React from "react";

import SecurityContext, {contextProps} from "./security_context";

/**
 * wrap component with security context
 * @param {Component} WrappedComponent
 * @return {function(*): JSX.Element}
 */
function withSecurity(WrappedComponent) {
    /**
     * Security wrapped component
     * @param {Object} passThroughProps
     * @return {JSX.Element}
     * @constructor
     */
    const WithSecurity = (passThroughProps) => (
        <SecurityContext.Consumer>
            {({permissions, isGranted}) => <WrappedComponent {...passThroughProps} isGranted={isGranted} permissions={permissions} />}
        </SecurityContext.Consumer>
    );

    WithSecurity.displayName = `WithSecurity(${WrappedComponent.displayName || WrappedComponent.name || "WrappedComponent"})`;
    WithSecurity.propTypes = contextProps;

    return WithSecurity;
}

export default withSecurity;
