// @ts-check

import {isEmergencyFunc} from "./is_emergency";

/**
 * @callback SortOpFunction
 * @param {PlanBox} a
 * @param {PlanBox} b
 * @return {number}
 */

/**
 * sort array of plan box by the priority and the start time
 * the latest and lowest priority ops comes first so that the high priority and earlier ops come on top
 *
 * @param {number} emergencyThreshold
 * @return {SortOpFunction} A negative number if a occurs before b; positive if the a occurs after b; 0 if they are equivalent.
 */
export const sortOpByPriorityAndStartTime =
    (emergencyThreshold) =>
    (
        {_internalTimestamps: internalTimestampsA, _priority: priorityA},
        {_internalTimestamps: internalTimestampsB, _priority: priorityB}
    ) => {
        const isEmergencyA = isEmergencyFunc(priorityA, emergencyThreshold) ? 1 : 0;
        const isEmergencyB = isEmergencyFunc(priorityB, emergencyThreshold) ? 1 : 0;
        // The start of op is the dtStart of duraRoomLockPre or duraRoomLock
        // If both are missing, the op won't be shown in the day view. But in order to avoid unexpected error, the start time of those ops are set to ""
        const startA = internalTimestampsA?.duraRoomLockPre?.dtStart || "";
        const startB = internalTimestampsB?.duraRoomLockPre?.dtStart || "";
        return isEmergencyA - isEmergencyB || startA.localeCompare(startB);
    };
