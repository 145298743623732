// @ts-check
import {APPOINTMENT_STATUS, ErrorUnpublishedAppointment, PROCEDURE_STATUS, SERVICEREQUEST_STATUS, STATUS_KEY} from "../../config/op_status";

/**
 * get frontend display state bases on OP state properties
 * @param {string} opStatus the overall state of the ServiceRequest / Appointment / Procedure / UnpublishedAppointment
 * @param {string} serviceRequestStatus the service request state of the OP
 * @param {boolean} isCutOver the status for the scheduler
 * @param {ReturnCodeScheduler|null} errorCode
 * @return {string|null}
 */
const opDisplayStatus = (opStatus, serviceRequestStatus, isCutOver, errorCode) => {
    if (isCutOver && opStatus === ErrorUnpublishedAppointment) {
        return STATUS_KEY.INCONSISTENT;
    }
    if ([APPOINTMENT_STATUS.BOOKED, APPOINTMENT_STATUS.PENDING].includes(opStatus)) {
        return STATUS_KEY.PLANNED;
    }
    if (opStatus === PROCEDURE_STATUS.IN_PROGRESS) {
        return STATUS_KEY.IN_PROGRESS;
    }
    if (opStatus === PROCEDURE_STATUS.COMPLETED) {
        return STATUS_KEY.DONE;
    }
    if (opStatus === SERVICEREQUEST_STATUS.REVOKED) {
        return STATUS_KEY.PERMANENTLY_REMOVED;
    }
    // ignore service request status constant prefix
    if (opStatus === APPOINTMENT_STATUS.CANCELLED && SERVICEREQUEST_STATUS.REVOKED.includes(serviceRequestStatus)) {
        return STATUS_KEY.PERMANENTLY_REMOVED;
    }
    if (opStatus === APPOINTMENT_STATUS.CANCELLED && SERVICEREQUEST_STATUS.ACTIVE.includes(serviceRequestStatus)) {
        return STATUS_KEY.ON_HOLD;
    }
    if (opStatus === APPOINTMENT_STATUS.CANCELLED && SERVICEREQUEST_STATUS.ON_HOLD.includes(serviceRequestStatus)) {
        return STATUS_KEY.ON_HOLD;
    }
    if (opStatus === ErrorUnpublishedAppointment) {
        return STATUS_KEY.CANT_PLAN;
    }
    if (opStatus === APPOINTMENT_STATUS.WAITLIST && errorCode >= 400) {
        return STATUS_KEY.CANT_PLAN;
    }

    return STATUS_KEY.INCONSISTENT;
};

export {opDisplayStatus};
