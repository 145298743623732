import config from "../../config/config.json";

/**
 * @typedef {Object} ObervationItem
 * @property {string} [unit]
 * @property {string} value
 */

/**
 * return the formatted string for patient's observation
 *
 * @param {ObervationItem} item
 * @return {string}
 */
export const formatObservation = (item) => {
    const {
        TEXT_PUNCTUATION: {HYPHEN}
    } = config;
    if (item?.unit) {
        return `${item.value} ${item.unit}`;
    }
    return item ? `${item.value}` : HYPHEN;
};
