import {STATUS_KEY} from "../../config/op_status";
import {opDisplayStatus} from "./op_status";

/**
 * @typedef {Object} result
 * @property {Boolean} ok
 * @property {Object<String, Array<string>>} errOps
 */
/**
 * verify ops in op backlog
 * @param {Array<PlanBox>} ops
 * @return {result} The verified appointments
 */
const verifyOpBacklogOps = (ops) => {
    const opsWithUnknownStatus =
        ops?.filter(
            (op) => opDisplayStatus(op._status, op._statusServiceRequest, op._isCutOver, op._error.code) === STATUS_KEY.INCONSISTENT
        ) ?? [];

    return {
        ok: opsWithUnknownStatus.length === 0,
        errOps: opsWithUnknownStatus.reduce((acc, curr) => {
            if (!acc[curr._status]) {
                acc[curr._status] = [curr.id];
            } else {
                acc[curr._status] = [...acc[curr._status], curr.id];
            }

            return acc;
        }, {})
    };
};

export default verifyOpBacklogOps;
