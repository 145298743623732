/**
 * sort array of objects by it's name
 * @param {Object} a
 * @param {String} a.name
 * @param {Object} b
 * @param {String} b.name
 * @return {Number} 1 | -1 | 0
 */
const sortArrayOfObjectsByName = (a, b) => {
    return a?.name?.localeCompare(b?.name, "de", {numeric: true, sensitivity: "base"}); // @TODO #14811: "de" need to be adjusted
};

export default sortArrayOfObjectsByName;
