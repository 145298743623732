/**
 * compare given property of two Objects
 * @param {Object} a
 * @param {Object} b
 * @param {String} orderBy name of property to be compared
 * @param {Boolean} isDesc true if is descending
 * @return {Number} 0, 1 or -1
 */
function descendingComparator(a, b, orderBy, isDesc) {
    if (Array.isArray(a[orderBy])) {
        const aString = a[orderBy].join();
        const bString = b[orderBy].join();
        return bString.localeCompare(aString, "de", {numeric: true, sensitivity: "base"});
    }

    const factor = isDesc ? 1 : -1;
    if (a[orderBy] === "" || a[orderBy] === null || a[orderBy] === undefined) return -1 * factor;
    if (b[orderBy] === "" || b[orderBy] === null || b[orderBy] === undefined) return 1 * factor;

    return b[orderBy].toString().localeCompare(a[orderBy].toString(), "de", {numeric: true, sensitivity: "base"});
}

/**
 * return comparator for descending or ascending
 * @param {String} order  desc or asc
 * @param {String} orderBy name of property to be compared
 * @return {Function}
 */
function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy, false) : (a, b) => -descendingComparator(a, b, orderBy, true);
}

/**
 *
 * @param {Array} array
 * @param {Function} comparator
 * @return {Array} sorted array by
 */
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export {stableSort, getComparator};
