import PropTypes from "prop-types";
import {createContext} from "react";

const DEFAULT_PERMISSIONS = ["execute:login"];

const initialContext = {
    permissions: DEFAULT_PERMISSIONS,
    isGranted: () => {}
};

const SecurityContext = createContext(initialContext);

const contextProps = {
    permissions: PropTypes.arrayOf(PropTypes.string),
    isGranted: PropTypes.func
};

export default SecurityContext;
export {contextProps};
