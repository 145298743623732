import permissionsConfig from "../../../config/permissions.json";

/**
 * @fileoverview Permission reference keys
 */
const PERMISSION = Object.freeze({
    EXECUTE_LOGIN: "execute:login",
    EXECUTE_LOGIN_PERMANENT: "execute:login:permanent",
    VIEW_DAYVIEW_FULLSCREEN: "view:dayview:fullscreen",
    MODIFY_USER_OWN: "modify:user:own",
    MODIFY_PRACTITIONER_OWN: "modify:practitioner:own",
    MODIFY_PRACTITIONER: "modify:practitioner",
    MODIFY_PLAN: "modify:plan",
    MODIFY_HCSERVICE: "modify:hcservice",
    MODIFY_LOCATION: "modify:location",
    MODIFY_USER: "modify:user",
    MODIFY_USER_NEXTADMIN: "modify:user:nextadmin",
    MODIFY_ORGANIZATION: "modify:organization",
    VIEW_SURGERYASSIGNMENT: "view:surgeryassignment",
    MODIFY_SURGERYASSIGNMENT: "modify:surgeryassignment",
    MODIFY_MEDICALCLEARANCE: "modify:medicalclearance",
    VIEW_DAYVIEW_FUTUREOPS: "view:dayview:futureops",
    MODIFY_PRINTDATES: "modify:printdates"
});
/**
 * @param {string} route - The pathname the user which to access
 * @param {string[]} rights - The configured permissions
 * @return {boolean} Wether the access to the route is allowed or not
 */
const isRouteAllowed = (route, rights) =>
    permissionsConfig.routes.every(({url: pattern, permissions}) =>
        route.match(pattern) ? permissions.some((permission) => Boolean((rights || []).includes(permission))) : true
    );

export {PERMISSION, isRouteAllowed};
