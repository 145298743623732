// @ts-check
/**
 * Sort inner function for the location
 * @callback SortByLocationOrderFunc
 * @param {string|object} a locationId
 * @param {string|object} b locationId
 * @return {Number}
 */
/**
 * Sort outer function for the location
 * @param {Object<string, {label: string, order: Number}>} locationInfos
 * @param {string} [key]
 * @return {SortByLocationOrderFunc}
 */
const sortLocation = (locationInfos, key) => (a, b) => {
    const aKey = key ? a[key] : a;
    const bKey = key ? b[key] : b;
    return (locationInfos?.[aKey]?.order ?? Number.MAX_SAFE_INTEGER) - (locationInfos?.[bKey]?.order ?? Number.MAX_SAFE_INTEGER);
};
export default sortLocation;
