// @ts-check
/**
 * Return chunks of the given array with given number of items
 * @param {Array} arr
 * @param {Number} chunkSize
 * @return {Array}
 */
export const sliceIntoChunks = (arr, chunkSize) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
};
