import {isRouteAllowed} from "./security";

const filterAllowedItems = (menu, permissions) =>
    menu
        .map((item) => {
            if (!item.pages && !item.route) {
                return item;
            }

            if (!item.pages && item.route && !isRouteAllowed(item.route, permissions)) {
                return null;
            }

            // filter for all permitted pages
            const pages = item.pages.map((page) => (isRouteAllowed(page.route, permissions) ? page : null)).filter(Boolean);

            return pages && pages.length ? {...item, pages} : null;
        })
        .filter(Boolean);

export {filterAllowedItems};
