/**
 * sort array of objects by the passed key
 *
 * @param {Object} a
 * @param {Object} b
 * @param {string} key The key that should be used to sort
 * @return {(1|-1|0)} A negative number if a occurs before b; positive if the a occurs after b; 0 if they are equivalent.
 */
export const sortArrayOfObjectsByKey = (a, b, key) => {
    return a?.[key]?.localeCompare(b?.[key], "de", {numeric: true, sensitivity: "base"}); // @TODO #14811 "de" need to be adjusted
};
