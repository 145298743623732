/**
 * @typedef patientName
 * @type {object}
 * @property {string} id
 * @property {string} name
 */

/**
 * search patient by text
 * @param {patientName []} patientNames
 * @param {string} text
 * @return {string []} patientIds
 */
const searchPatientByText = (patientNames, text) => {
    const patientIds = [];
    for (const patient of patientNames) {
        if (patient.name?.toLowerCase().includes(text?.toLowerCase())) {
            patientIds.push(patient.id);
        }
    }
    return [...new Set(patientIds)].sort();
};

export default searchPatientByText;
