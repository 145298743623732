// @ts-check
/**
 * @typedef {Object} errOps
 * @property {String[]} missingTimestamps
 * @property {String[]} missingLocation
 * @property {String[]} wrongDates
 */

/**
 * verify appointments
 * @param {PlanBox[]} ops
 * @return {{ok: boolean, okOps: PlanBox[], errOps: errOps}} The verified appointments
 */
const verifyAppointments = (ops) => {
    let ok = true;
    const okOps = [];
    const errOps = {missingTimestamps: [], missingLocation: [], wrongDates: []};

    for (const opData of ops) {
        let hasError = false;

        // check timestamps of customer ops
        const duraRoomLockExists = Boolean(
            opData._internalTimestamps?.duraRoomLock?.dtStart && opData._internalTimestamps.duraRoomLock.dtEnd
        );

        // check timestamps of ki/real ops
        const duraRoomLockPreAndPostExists = Boolean(
            opData._internalTimestamps?.duraRoomLockPre?.dtStart && opData._internalTimestamps.duraRoomLockPost?.dtEnd
        );
        if (!duraRoomLockExists && !duraRoomLockPreAndPostExists) {
            errOps.missingTimestamps.push(opData.id);
            hasError = true;
            ok = false;
        }

        // check location
        if (!opData._location?.reference) {
            errOps.missingLocation.push(opData.id);
            hasError = true;
            ok = false;
        }

        if (!hasError) {
            okOps.push(opData);
        }
    }

    return {ok, okOps, errOps};
};

export default verifyAppointments;
