import cloneDeep from "lodash/cloneDeep";

/**
 * get intervention from opData
 * @param {object} opData
 * @param {string} newRoom
 * @return {object} opDataMod
 */
const setRoom = (opData, newRoom) => {
    const opDataMod = cloneDeep(opData);
    opDataMod._location.reference = newRoom;

    return opDataMod;
};

export default setRoom;
