/**
 * @fileoverview hook for security context
 *
 * usage:
 * <code>
 *     const MyComponent = () => {
 *        const {isGranted} = useSecurity();
 *
 *        const handleClick = () => {
 *            if (isGranted(PERMISSION.MODIFY_USER)) {
 *               ...
 *            }
 *        };
 *     };
 * </code>
 */

import {useContext} from "react";

import SecurityContext from "./security_context";

/**
 * security hook
 * @return {{permissions: Array, isGranted: Function}}
 */
function useSecurity() {
    const {permissions, isGranted} = useContext(SecurityContext);

    return {
        permissions,
        isGranted
    };
}

export default useSecurity;
